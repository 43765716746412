import React, { useState } from "react";
import moment from "moment";
import {MdModeEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsXCircleFill } from "react-icons/bs";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import { toast } from "react-toastify";
import productMethod from "../../Services/product";
import common from "../../Services/common";

const Product = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [status, setStatus] = useState(props.product.status);

  const handleChange = (e, field) => {
    let fieldValue;

    if (e.target.type === "checkbox") {
      fieldValue = e.target.checked;
      setStatus(fieldValue);
    }

    setFields({
      ...fields,
      [field]: fieldValue,
    });

    if (field === "status") {
      const id = props.product.id;

      productMethod.status(id, fieldValue).then((res) => {
        const position = toast.POSITION.TOP_RIGHT;

        if (res?.data?.status) {
          toast.success(res.data.message, { position });
        } else {
          toast.error(res.data.message, { position });
        }
      });
    }
  };

  const product = props.product;
  return (
    <tr key={product.id}>
      <td>{props.index + 1}</td>
      <td>
        <img
          src={product?.Product_images[0]?.image}
          alt="govihub"
          className="img-thumbnail"
          style={{ width: "60px", minWidth: "60px" }}
        />
      </td>
      <td>{product?.name}</td>
      <td>{common.FormatAmount(product?.price)}</td>
      <td>
        <FormGroup switch>
          <Input
            type="switch"
            name="status"
            onChange={(event) => handleChange(event, "status")}
            checked={status}
          />
        </FormGroup>
      </td>
      <td>{moment(product?.createdAt).format("D MMM,  YYYY")}</td>

      <td className="text-center">
        <Dropdown
          toggle={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          isOpen={dropdownOpen}
          color="primary"
          className="modal_Dropdown"
        >
          <DropdownToggle className="dropdown_btn">
            <FaEllipsisV />
          </DropdownToggle>
          <DropdownMenu container="body" right>
            <span onClick={() => props.openEditModal(product.id)}>
              <DropdownItem>
                <MdModeEdit
                  className="me-3 edit-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />{" "}
                Edit
              </DropdownItem>
            </span>
            <span onClick={() => props.deleteProduct(product.id)}>
              <DropdownItem>
                <BsXCircleFill
                  className="me-3 remove-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />{" "}
                Delete
              </DropdownItem>
            </span>
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default Product;
