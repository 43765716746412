import React, { useState, useEffect } from "react";
import RecentDonors from "./RecentDonors";
import { Helmet } from "react-helmet";
import { Col, Row, Table, Spinner, Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import dmf from "../../../Services/dmfsubmission";
import { Link } from "react-router-dom";

const Index = (props) => {
  const [fields, setFields] = useState({});
  const [users, setUsers] = useState({});
  const [loader, setLoader] = useState(false);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Dashboard : seeding justice</title>
      </Helmet>

      <Card className="px-3 recent_donors">
        <CardBody className="mt-2">
          <Row>
            <Col md={6} sm={12}>
              <h4 className="mb-0 title-font" style={{ fontSize: "18px" }}>
                Recent Orders
              </h4>
            </Col>
            <Col sm={12} md={6} className="text-end">
              <Link
                to="/admin/orders"
                color="light"
                type="button"
                className="mt-2 mt-md-0 btn btn-outline-secondary view-more-btn"
                size="md"
                style={{ borderRadius: "30px" }}
              >
                View More
              </Link>
            </Col>
          </Row>
          <LoadingOverlay
            active={loader}
            spinner={
              <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
            }
            fadeSpeed={100}
            classNamePrefix="mitiz"
          >
            <Table
              responsive
              className="newThemeTable recentDonorTable px-3 mt-4"
            >
              <thead>
                <tr>
                  <th scope="col" className="border-top-0" width={7 + "%"}>
                    S.No.
                  </th>
                  <th scope="col">Order Id</th>
                  <th scope="col">User</th>
                  <th scope="col">Price</th>
                  <th scope="col">Status</th>
                  <th scope="col">Order date</th>
                  {/* 
                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th> */}
                </tr>
              </thead>

              <tbody>
                {props?.fields?.recent_order?.length > 0
                  ? props?.fields?.recent_order?.map((order, index) => (
                      <RecentDonors order={order}  index={index} key={`key-order-${index}`} />
                    ))
                  : !loader && (
                      <tr>
                        <td key={0} colSpan="6">
                          <p className="text-center">Recent order not found.</p>
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
