// import React from "react";
import Dashboard from "../Backend/Dashboard";
import Banners from "../Backend/Banners";
import Error from "../Frontend/Error";
import Setting from "../Backend/Setting";
import Category from "../Backend/Category";
import Coupons from "../Backend/Coupons";
import Products from "../Backend/Products";
import Orders from "../Backend/Orders"

const AdminRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    element: Dashboard,
    authentication: true,
  },
  {
    path: "banners",
    name: "Banners",
    element: Banners,
    authentication: true,
  },
  {
    path: "products",
    name: "Products",
    element: Products,
    authentication: true,
  },
  {
    path: "coupons",
    name: "Coupons",
    element: Coupons,
    authentication: true,
  },
  {
    path: "orders",
    name: "Orders",
    element: Orders,
    authentication: true,
  },

  {
    path: "settings",
    name: "settings",
    element: Setting,
    authentication: true,
  },

  {
    path: "category",
    name: "Category",
    element: Category,
    authentication: true,
  },

  {
    path: "error",
    name: "Error",
    element: Error,
    authentication: true,
  },
];

export default AdminRoutes;
