import http from "./http";

const coupon = {
  add: (data) => http.post("/admin/coupon/create", data),
  update: (data) => http.post("/admin/coupon/update", data),
  list: (param) => http.get("/admin/coupon/list", { params: param }),
  delete: (param) => http.delete(`/admin/coupon/delete?id=${param}`),
  getOne: (param) => http.get(`/admin/coupon/view?id=${param}`),

};

export default coupon;
