import http from "./http";

const banner = {
  add: (data) => http.post("/admin/banner/create", data),

  status: (id, status) =>
    http.post(`/admin/banner/activate-deactivate?id=${id}&status=${status}`),

  update: (data) => http.post("/admin/banner/update", data),
  deleteImage: (data) => http.post("/common/delete-image", data),
  uploadImage: (data) => http.post("/common/upload-image", data),

  list: (param) => http.get("/admin/banner/list", { params: param }),
  categorylist: (param) =>
    http.get("/admin/prevetted-categories", { params: param }),
  prevttedcategorylist: (param) =>
    http.get("/user/prevetted-categories", { params: param }),
  delete: (param) => http.delete(`/admin/banner/delete?id=${param}`),
  getOne: (param) => http.get(`/admin/banner/view?id=${param}`),
  getExcel: (param) =>
    http.get("/admin/prevetted-grantees/export", {
      params: param,
      responseType: "blob",
    }),
};

export default banner;
