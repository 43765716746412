import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
  FormText,
} from "reactstrap";
import { TiDelete } from "react-icons/ti";
import LoadingOverlay from "react-loading-overlay";
import product from "../../Services/product";
import banner from "../../Services/banner";
import Select from "react-select";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddEditProduct = (props) => {
  const [fields, setFields] = useState({
    hot_deal: true,
    featured: true,
  });
  const [file, setFile] = useState([]);
  const [categories, setCategories] = useState({});
  const [imagePreviews, setImagePreviews] = useState([]);
  // const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [reFetch, setreFetch] = useState(false);
  const [content, setContent] = useState("");

  LoadingOverlay.propTypes = undefined;

  const handleChange = (e, field) => {
    setFields({
      ...fields,
      [field]:
        e.target.type === "checkbox"
          ? e.target.checked
            ? true
            : false
          : e.target.value,
    });
  };
  const handleCategoryChange = (value, { action }) => {
    if (action == "clear") {
      fields["categories"] = "";
    } else {
      fields["categories"] = value;
    }
    setFields(fields);
    setErrors({ ...errors, ["categories"]: "" });
  };
  const handleContentChange = (content) => {
    setContent(content);
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.name) {
      errors["name"] = "Name can not be empty";
      formIsValid = false;
    }
    if (!fields.price) {
      errors["price"] = "Price can not be empty";
      formIsValid = false;
    }
    if (!fields.total_stock) {
      errors["total_stock"] = "Total stock can not be empty";
      formIsValid = false;
    }
    if (!fields.sort_description) {
      errors["sort_description"] = "Short description can not be empty";
      formIsValid = false;
    }
    if (!content) {
      errors["content"] = "Description can not be empty";
      formIsValid = false;
    }
    if (!fields.categories) {
      errors["categories"] = "Category can not be empty";
      formIsValid = false;
    }
    // if (!fields.Product_images || fields.Product_images.length === 0) {
    //   errors["Product_images"] = "Image can not be empty";
    //   formIsValid = false;
    // }

    setErrors(errors);
    return formIsValid;
  };
  const getCategories = () => {
    setLoader(true);
    product
      .categorylist()
      .then((res) => {
        if (res.data.status) {
          let categories = [];
          res?.data?.data?.categories?.forEach((category, index) => {
            categories[index] = {
              label: category.name,
              value: category.id,
            };
          });
          setCategories(categories);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };
  const handleFileUpload = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) => ({
      id: null,
      product_id: null,
      image: URL.createObjectURL(file),
      file: file,
    }));

    setFile((prevFiles) => {
      const updatedFilesArray = Array.isArray(prevFiles)
        ? prevFiles
        : [prevFiles];
      return [...updatedFilesArray, ...updatedFiles];
    });

    const updatedPreviews = [
      ...imagePreviews,
      ...updatedFiles.map((file) => file.image),
    ];
    setImagePreviews(updatedPreviews);

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });

    banner
      .uploadImage(formData)
      .then((res) => {
        if (res.data.status) {
          setFields((prevFields) => ({
            ...prevFields,
            Product_images: [
              ...(prevFields?.Product_images || []),
              { image: res?.data?.data?.filePath },
            ],
          }));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Image uploading error", error);
      });
  };

  const getProduct = () => {
    let params = props.productId;
    setLoader(true);
    product
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          let fields = res?.data?.data?.product;
          if (res?.data?.data?.product?.Product_categories !== null) {
            let categories = [];
            res?.data?.data?.product?.Product_categories.forEach(
              (arr, index) => {
                categories[index] = {
                  label: arr?.Category?.name,
                  value: arr?.Category?.id,
                };
              }
            );
            fields["categories"] = categories;
          }
          // fields["content"] = res?.data?.data?.product?.description;
          setContent(res?.data?.data?.product?.description);
          setFields(fields);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleImageDelete = (img) => {
    let params = { filePath: img };
    product
      .deleteImage(params)
      .then((res) => {
        if (res.data.status) {
          setFile(null);
          setImagePreviews(null);
          setFields((prevFields) => ({
            ...prevFields,
            Product_images: prevFields.Product_images.filter(
              (image) => image.image !== img
            ),
          }));
          const fileInput = document.getElementById("file");
          if (fileInput) {
            fileInput.value = "";
          }
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Image delete error", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // let params = fields;
      const combinedImages = [
        ...(fields?.Product_images || []),
        // ...images?.map((file) => ({ image: file.image })),
      ];
      // Rest of your code
      let params = fields;
      params["description"] = content;
      params["Product_images"] = combinedImages.map((image) => ({
        image: image.image,
      }));
      // params["Product_images"] = images.map((image) => ({
      //   image: image,
      // }));

      params["Product_categories"] = fields?.categories?.map((res) => ({
        category_id: res?.value,
      }));

      setIsloader(true);
      const productMethod = props.productId ? product.update : product.add;
      productMethod(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setIsloader(true);
            props.handleClose();
            props.getProducts();
          } else {
            let errors = {};
            for (let key in res.data.errors) {
              errors[key] = res.data.errors[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];

  useEffect(() => {
    if (props.productId) {
      getProduct(props.productId);
    }
    getCategories();
  }, [reFetch]);

  return (
    <>
      <Modal isOpen={props.open} size="lg" className="admin new-grantee-modal">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            {props.productId ? "Update " : "Add "}Product
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={<Spinner style={{ color: "#00344B" }} />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder=""
                      type="text"
                      value={fields["name"] ? fields["name"] : ""}
                      onChange={(event) => handleChange(event, "name")}
                      invalid={errors["name"] ? true : false}
                    />
                    <FormFeedback>{errors["name"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="price">Price</Label>
                    <Input
                      id="price"
                      name="price"
                      placeholder=""
                      type="text"
                      value={fields["price"] ? fields["price"] : ""}
                      onChange={(event) => handleChange(event, "price")}
                      invalid={errors["price"] ? true : false}
                    />
                    <FormFeedback>{errors["price"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="total_stock">Total Stock</Label>
                    <Input
                      id="total_stock"
                      name="total_stock"
                      placeholder=""
                      type="text"
                      value={fields["total_stock"] ? fields["total_stock"] : ""}
                      onChange={(event) => handleChange(event, "total_stock")}
                      invalid={errors["total_stock"] ? true : false}
                    />
                    <FormFeedback>{errors["total_stock"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="categories">Categories</Label>
                    <Select
                      id="categories"
                      name="categories"
                      placeholder={<div>Select categories...</div>}
                      isMulti
                      value={fields["categories"] ? fields["categories"] : []}
                      options={categories}
                      classNamePrefix="select"
                      onChange={handleCategoryChange}
                    />
                    {errors["categories"] && (
                      <small className="fa-1x text-danger">
                        {errors["categories"]}
                      </small>
                    )}
                    <FormFeedback>{errors["categories"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="sort_description">Short Description</Label>
                    <Input
                      id="sort_description"
                      sort_description="sort_description"
                      placeholder=""
                      type="textarea"
                      value={
                        fields["sort_description"]
                          ? fields["sort_description"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "sort_description")
                      }
                      invalid={errors["sort_description"] ? true : false}
                    />
                    <FormFeedback>{errors["sort_description"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="content">Description</Label>

                    <ReactQuill
                      theme="snow"
                      name="content"
                      id="content"
                      value={content}
                      modules={modules}
                      formats={formats}
                      onChange={handleContentChange}
                      invalid={errors["content"] ? true : false}
                      style={{ minHeight: "250px" }}
                    />
                    {errors["content"] && (
                      <FormText color="danger" size="md">
                        {errors["content"]}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Label for="Image">Image</Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="image_uploader">
                          <input {...getInputProps()} />
                          <p>
                            Drag 'n' drop some file here, or{" "}
                            <span className="image_click">click</span> to select
                            file
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {/* {imagePreviews?.map((preview, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        style={{ width: "80px" }}
                      />
                      <TiDelete
                        onClick={() => handleImageDelete(preview)}
                        className="delete-icon"
                      />
                    </div>
                  ))} */}
                  {Array.isArray(fields?.Product_images) &&
                    fields?.Product_images.map((ele, i) => (
                      <div key={i} className="image-preview">
                        {ele && ele.image && (
                          <img
                            src={ele.image}
                            alt={`Preview ${i}`}
                            style={{ width: "80px" }}
                          />
                        )}
                        {ele && ele.image && (
                          <TiDelete
                            onClick={() => handleImageDelete(ele?.image)}
                            className="delete-icon"
                          />
                        )}
                      </div>
                    ))}
                </Col>
                {errors["Product_images"] && (
                  <FormText color="danger" size="md">
                    {errors["Product_images"]}
                  </FormText>
                )}
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <FormGroup switch>
                    <Label>
                      <Input
                        type="switch"
                        name="featured"
                        onChange={(event) => handleChange(event, "featured")}
                        // value={0}
                        checked={fields["featured"] == true ? true : false}
                      />
                      Featured
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup switch>
                    <Label>
                      <Input
                        type="switch"
                        name="hot_deal"
                        onChange={(event) => handleChange(event, "hot_deal")}
                        // value={0}
                        checked={fields["hot_deal"] == 1 ? 1 : 0}
                      />
                      Hot deal
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="submit-Id-button"
              size="md"
              type="submit"
              disabled={isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              {props.productId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditProduct;
