import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button, Card, CardBody } from "reactstrap";
import Search from "../Search";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import banner from "../../Services/banner";
// import Product from "./Product";
import product from "../../Services/product";
import AddEditProduct from "./AddEditProduct";
import Product from "./Product";

const Index = (props) => {
  const [products, setProducts] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [productId, setProductId] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});
  const [totalPages, setTotalPages] = useState([]);
  const [searchOpen, setsearchOpen] = useState(false);
  const [exportFields, setExportFields] = useState({});
  const [searchFields, setSearchFields] = useState([
    {
      label: "Name",
      name: "search",
      type: "text",
      col: 4,
    },

  ]);
  LoadingOverlay.propTypes = undefined;

  const openEditModal = (category_id) => {
    setOpen(true);
    setProductId(category_id);
  };

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const getProducts = (fields = {}) => {
    setLoader(true);
    let params;
    if (Object.keys(searchValue).length > 0) {
      params = {
        ...fields,
        ...searchValue,
        ...sort,
        page,
      };
    } else {
      params = { ...fields, ...sort, page };
    }
    product
      .list(params)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          setProducts(res?.data?.data?.products);
          if (!searchValue) {
            setPage(res?.data?.data?.pagination?.currentPage);
          }
          setTotalPages(res?.data?.data?.pagination?.total / res.data.data.pagination?.pageSize);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const deleteProduct = (id) => {
    if (window.confirm("Are you sure to delete this product?")) {
      product.delete(id).then((res) => {
        if (res?.data?.status) {
          const newBanner = products.filter((item) => item.id !== id);
          setProducts(newBanner);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const getSearchFields = (fields = {}) => {
    setExportFields(fields);
    if (
      (Object.keys(searchValue).length === 0 &&
        Object.keys(fields).length === 0) ||
      page == 1
    ) {
      getProducts(fields);
    } else {
      setPage(1);
    }
    setTotalPages(totalPages / 10);
  };

  useEffect(() => {
    getProducts(page, sort);
  }, [page, sort]);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Product : Govihub </title>
      </Helmet>
      <Row>
        <Col lg={6} md={5} sm={12}>
          <h4 className="mb-0 title-font">Product</h4>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <p className="mb-0">Product</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col lg={6} md={7} sm={12} className="text-right">
          <Row>
            <Col sm={12} md={4}>
             
            </Col>
            <Col sm={12} md={4}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                size="md"
                block
                className="mt-md-0 mt-2 search_button"
                style={{ borderRadius: "30px" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
            <Col sm={12} md={4}>
              <Button
                type="button"
                onClick={() => setOpen(true)}
                size="md"
                block
                className="mt-md-0 mt-2 add_button"
                style={{ borderRadius: "30px" }}
              >
                Add New
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Search
        isOpen={searchOpen}
        searchFields={searchFields}
        service={products}
        fileName={"Banner"}
        exportExcel={true}
        heading="Search Banner"
        onSubmit={getSearchFields}
        setFields={setSearchValue}
        fields={searchValue}
      />
      <Card className="mt-4">
        <CardBody>
          <Row className="mt-3">
            <Col>
              <LoadingOverlay
                active={false}
                spinner={
                  <Spinner
                    style={{ height: 60, width: 60, color: "#00344B" }}
                  />
                }
                fadeSpeed={100}
                classNamePrefix="mitiz"
              >
                <Table responsive className="newThemeTable">
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0" width={7 + "%"}>
                        S.No.
                      </th>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "org_name")}
                        >
                          Image
                        </span>
                      </th>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "org_name")}
                        >
                          Name
                        </span>
                      </th>
                      <th scope="col">
                        
                          Price
                      </th>
                      <th scope="col">
                        
                          Status
                      </th>

                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "created_at")}
                        >
                          Added On
                        </span>
                      </th>

                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.length > 0
                      ? products?.map((product, index) => (
                          <Product
                            index={index}
                            product={product}
                            key={`key-product-${index}`}
                            openEditModal={openEditModal}
                            deleteProduct={deleteProduct}
                          />
                        ))
                      : !loader && (
                          <tr>
                            <td key={0} colSpan="6">
                              <p className="text-center">Record not found.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </LoadingOverlay>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <tr style={{ display: "flex" }}>
        <td colSpan="6" style={{ margin: "auto" }}>
          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-center mb-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </td>
      </tr>
      {open && (
        <AddEditProduct
          open={open}
          handleClose={() => {
            setOpen();
            setProductId(null);
          }}
          productId={productId}
          getProducts={getProducts}
        />
      )}
    </div>
  );
};

export default Index;
