import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import adminsetting from "../../Services/adminsettings";
import common from "../../Services/common";
const ChangePassword = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [singleError, setSingleError] = useState("");
  const [loader, setLoader] = useState(false);
  
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    let params = fields;
    setLoader(true);
    adminsetting
      .adminPassword(params)
      .then((res) => {
        setLoader(true);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          setFields({});
          setErrors({});
        } else {
          if (common.isJson(res.data.message)) {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
          } else {
            setSingleError(res.data.message);
          }
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setErrors(errors);
        setLoader(false);
      });
  };
  return (
    <div className="mt-4" style={{ minHeight: 300 }}>
      <Col md={12} className="mt-2">
        <form onSubmit={handlePasswordSubmit}>
          <Col md={12}>
            <FormGroup>
              <Label for="old_password">Old Password</Label>
              <Input
                id="old_password"
                name="old_password"
                placeholder="Enter old password"
                type="password"
                value={fields["old_password"] ? fields["old_password"] : ""}
                onChange={(event) => handleChange(event, "old_password")}
                invalid={errors["old_password"] ? true : false}
              />
              <FormFeedback>{errors["old_password"]}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="password">New Password</Label>
              <Input
                id="password"
                name="password"
                placeholder="Enter new password"
                type="password"
                value={fields["password"] ? fields["password"] : ""}
                onChange={(event) => handleChange(event, "password")}
                invalid={errors["password"] ? true : false}
              />
              <FormFeedback>{errors["password"]}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="password_confirmation">Confirm Password</Label>
              <Input
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Enter confirm password"
                type="password"
                value={
                  fields["password_confirmation"]
                    ? fields["password_confirmation"]
                    : ""
                }
                onChange={(event) =>
                  handleChange(event, "password_confirmation")
                }
                invalid={errors["password_confirmation"] ? true : false}
              />
              <FormFeedback>{errors["password_confirmation"]}</FormFeedback>
            </FormGroup>{" "}
          </Col>
          {singleError != "" ? (
            <p className="text-danger text-center mb-1">{singleError}</p>
          ) : (
            ""
          )}

          <Col className="d-flex justify-content-start">
            <Button color="success"  className="submit-Id-button" type="submit">
              {loader && <Spinner size="sm" className="mx-1" />}
              Submit
            </Button>
          </Col>
        </form>
      </Col>
    </div>
  );
};

export default ChangePassword;
