import React from "react";
import { Card, Nav, NavItem } from "reactstrap";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const SettingSidebar = ({ emailPass, setEmailPass }) => {
  return (
    <Card className="shadow support_aside" style={{width: "100%"}}>
      <Nav tabs fill>
        <NavItem>
          <Link
          id="sub-menu-font"
            className={emailPass == 1 ? "active nav-link" : "nav-link"}
            onClick={() => setEmailPass(1)}
          >
            <FaEdit /> Profile Settings
          </Link>
        </NavItem>
          
        <NavItem>
          <Link
            id="sub-menu-font"
            className={emailPass == 2 ? "active nav-link" : "nav-link"}
            onClick={() => setEmailPass(2)}
          >
            <RiLockPasswordFill /> Change Password
          </Link>
        </NavItem>

        <NavItem>
          <Link
            id="sub-menu-font"
            className={emailPass == 3 ? "active nav-link" : "nav-link"}
            onClick={() => setEmailPass(3)}
          >
            <MdEmail /> Global Configs
          </Link>
        </NavItem>
      </Nav> 
    </Card>
  );
};

export default SettingSidebar;
