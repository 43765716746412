import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import StatusCard from "./StatusCard";
import RecentDonors from "./RecentDonors/";
import dashboard from "../../Services/dashboard";

const Index = () => {
  const [chart, setChart] = useState([]);
  const [fields, setFields] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [toolTipData, settoolTipData] = useState('')

  
  const getDashboards = () => {
    setLoader(true);
    dashboard
      .list()
      .then((res) => {
        console.log("HELLO",res);
        if (res?.data?.status) {
          setFields(res?.data?.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

useEffect(() => {
  getDashboards()
}, [])


  return (
    <section className="dashbord_container">
      <Row>
        <Col md={9} sm={12}>
          <h4 className="mb-0 title-font">Dashboard</h4>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12}>
          <div className="support_aside mb-3">
            <StatusCard fields={fields} Loader={Loader} />
          </div>
        </Col>
              </Row>
      <Row className="mt-5">
        <Col md={12}>
          <RecentDonors fields={fields} />
        </Col>
        
      </Row>
    </section>
  );
};

export default Index;
