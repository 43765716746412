import { createStore } from "redux";

let apiUrl, baseUrl;
if (document.URL.includes("localhost")) {
  apiUrl = "https://api.govihubb.com";
  baseUrl = "https://api.govihubb.com";
} else {
  apiUrl = "https://api.govihubb.com";
  baseUrl = "https://api.govihubb.com";
}
let initialState = {
  apiUrl: apiUrl,
  baseUrl: baseUrl,
};
if (localStorage.getItem("access_token")) {
  initialState = { ...initialState, access_token: localStorage.getItem("access_token") };
}
if (localStorage.getItem("name")) {
  initialState = {
    ...initialState,
    name: localStorage.getItem("name"),
  };
}
if (localStorage.getItem("email")) {
  initialState = {
    ...initialState,
    email: localStorage.getItem("email"),
  };
}
if (localStorage.getItem("mobile")) {
  initialState = {
    ...initialState,
    mobile: localStorage.getItem("mobile"),
  };
}
if (localStorage.getItem("Is_Admin")) {
  initialState = {
    ...initialState,
    Is_Admin: localStorage.getItem("Is_Admin"),
  };
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "UPDATE-EMAIL":
      return { ...state, ...rest };
    case "login":
      return { ...state, ...rest };
    case "logout":
      localStorage.clear();
      const initialState = {
        apiUrl: apiUrl,
        baseUrl: baseUrl,
      };
      return (state = initialState);
    default:
      return state;
  }
};

const store = createStore(
  changeState,
  window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_()
);
store.subscribe(() => {
  console.log(store);
});
export default store;
