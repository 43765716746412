import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import adminsetting from "../../Services/adminsettings";
const EditEmail = () => {
  const email = useSelector((state) => state.email);
  const [fields, setFields] = useState({ email });
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    let params = fields;
    setIsloader(true);
    adminsetting.updateEmail(params).then((res) => {
      if (res.data.status) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsloader(false);
        setErrors({});
        localStorage.setItem("email", fields.email);
        let payload = { email: fields.email };
        dispatch({ type: "UPDATE-EMAIL", ...payload });
      } else {
        let errors = {};
        for (let key in res.data.errors) {
          errors[key] = res.data.errors[key];
        }
        setErrors(errors);
        setIsloader(false);
      }
    });
  };
  return (
    // <div className="d-flex justify-content-start">
      // <Col md={8}>
        <div className="mt-4" style={{ minHeight: 300 }}>
          <Col md={12} className="mt-2">
            <Form onSubmit={handleEmailSubmit}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  placeholder=""
                  type="text"
                  value={fields["email"] ? fields["email"] : ""}
                  onChange={(event) => handleChange(event, "email")}
                  invalid={errors["email"] ? true : false}
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
              <Col className="d-flex justify-content-start">
                <Button color="success"  className="submit-Id-button" type="submit" disabled={isloader}>
                  {isloader && <Spinner size="sm" className="mx-1" />}
                  Submit
                </Button>
              </Col>
            </Form>
          </Col>
        </div>
      // </Col>
    // </div>
  );
};

export default EditEmail;
