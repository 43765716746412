import http from "./http";

const order = {
  status: (data) => http.post("/admin/order/update-status", data),

  list: (param) => http.get("/admin/order/list", { params: param }),
  getOne: (param) => http.get(`/admin/order/view?id=${param}`),
  // status: (id, status) =>
  //   http.post(`/admin/order/update-status?id=${id}&status=${status}`),

  // order/update-status

};

export default order;
