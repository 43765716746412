import React, { Suspense } from "react";
import "./App.css";
import '../src/Frontend/scss/frontendStyle.scss';
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminRoutes from "./Routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "./Frontend/Error";
import ScrollToTop from "./Services/scrollTop";
const AdminLayout = React.lazy(() => import("../src/Layouts/AdminLayout"));
const Login = React.lazy(() => import("../src/Frontend/Login"));

function App() {
  
  const access_token = useSelector((state) => state.access_token);
  const checkAuth = (route) => {
    if (route.authentication) {
      if (access_token === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        <ToastContainer />
        <Suspense
          fallback={
            <div className="vh-100 d-flex align-items-center justify-content-center">
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path="/admin" element={<AdminLayout />}>
              {AdminRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    checkAuth(route) ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
            </Route>
            <Route path="/" element={<Login />} />
            
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
