import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import { TiDelete } from "react-icons/ti";
import LoadingOverlay from "react-loading-overlay";
import prevettedsj from "../../Services/category";
import category from "../../Services/category";
import coupon from "../../Services/coupon";

const AddEditCoupon = (props) => {
  const [fields, setFields] = useState({type:"fixed_amount"});
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [reFetch, setreFetch] = useState(false);

  LoadingOverlay.propTypes = undefined;
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.code) {
      errors["code"] = "Code can not be empty";
      formIsValid = false;
    }
    // if (!fields.type) {
    //   errors["type"] = "Coupon type can not be empty";
    //   formIsValid = false;
    // }
    if (!fields.discount) {
      errors["discount"] = "Discount type can not be empty";
      formIsValid = false;
    }


    setErrors(errors);
    return formIsValid;
  };



  const getCoupon = () => {
    let params = props.couponId;
    setLoader(true);
    coupon
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          setFields(res?.data?.data?.coupon);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
     
      setIsloader(true);
      const couponMethod = props.couponId ? coupon.update : coupon.add;
      couponMethod(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setIsloader(true);
            props.handleClose();
            props.getCoupons();
          } else {
            let errors = {};
            for (let key in res?.data?.errors) {
              errors[key] = res?.data?.errors[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };
  useEffect(() => {
    if (props.couponId) {
      getCoupon(props.couponId);
    }
  }, [reFetch]);

  return (
    <>
      <Modal isOpen={props.open} size="md" className="admin new-grantee-modal">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            {props.couponId ? "Update " : "Add "}Coupon
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={<Spinner style={{ color: "#00344B" }} />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="code">code</Label>
                    <Input
                      id="code"
                      name="code"
                      placeholder=""
                      type="text"
                      value={fields["code"] ? fields["code"] : ""}
                      onChange={(event) => handleChange(event, "code")}
                      invalid={errors["code"] ? true : false}
                    />
                    <FormFeedback>{errors["code"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

           
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <FormGroup>
                      <Input
                        id="type"
                        name="type"
                        type="select"
                        value={fields["type"] ? fields["type"] : ""}
                        onChange={(event) => handleChange(event, "type")}
                        invalid={errors["type"] ? true : false}
                      >
                        <option value="fixed_amount">Fixed Amount</option>
                        <option value="percentage">Percentage</option>
                      </Input>
                    </FormGroup>
                    <FormFeedback>{errors["type"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="discount">Percentage /Amount</Label>
                    <Input
                      id="discount"
                      name="discount"
                      placeholder=""
                      type="text"
                      value={fields["discount"] ? fields["discount"] : ""}
                      onChange={(event) => handleChange(event, "discount")}
                      invalid={errors["discount"] ? true : false}
                    />
                    <FormFeedback>{errors["discount"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="submit-Id-button"
              size="md"
              type="submit"
              disabled={isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              {props.couponId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditCoupon;
