import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import SettingSidebar from "./SettingSidebar";
import EditEmail from "./EditEmail";
import ChangePassword from "./ChangePassword";
import GlobalConfig from "./GlobalConfig";

const Index = () => {
  const [emailPass, setEmailPass] = useState(1);

  return (
    <>
      <Helmet>
        <title>Edit Profile : Seeding Justice</title>
      </Helmet>
      <div className="pt-5">
        <Row>
          <Col md={4} lg={3}>
            <SettingSidebar emailPass={emailPass} setEmailPass={setEmailPass} />
          </Col>
          <Col md={8} lg={9}>
            <Card
              className="shadow support_aside mb-5"
              style={{ width: "100%" }}
            >
              <CardHeader className="py-3">
                <h4 className="mb-0 title-font fs-5">
                  {emailPass == 1 ? "Update Email" : ""}
                  {emailPass == 2 ? "Change Password" : ""}
                  {emailPass == 3 ? "Global configuration" : ""}
                </h4>
              </CardHeader>
              <CardBody>
                {emailPass == 1 ? <EditEmail /> : null}

                {emailPass == 2 ? (
                    <Col md={12}>
                      <ChangePassword />
                    </Col>
                ) : null}

                {emailPass == 3 ? (
                  <>
                      <Col md={12}>
                        <GlobalConfig />
                      </Col>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Index;
