import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Badge,
} from "reactstrap";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import common from "../../../Services/common";
import RecentDonorsDetails from "./RecentDonorsDetails";
import { AiFillCheckCircle } from "react-icons/ai";

const RecentDonors = (props) => {
  console.log(props);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [donorId, setDonorId] = useState("");
  const [price, setPrice] = useState([]);
  const order = props.order;

  const toggleDetailsModal = (id) => {
    setOpenDetailsModal(true);
    setDonorId(id);
  };

  useEffect(() => {
    order?.Order_items?.map((ele, i) => {
      return setPrice(ele?.Order_product?.price);
    });
  }, []);

  return (
    <>
      <tr key={order?.id}>
        <td>{props?.index + 1}</td>
        <td>#{order?.id}</td>
        <td>{order?.User?.name}</td>
        <td>₹{price}</td>
        <td>
          {" "}
          <Badge
            color={
              order?.status === "ordered"
                ? "primary"
                : order?.status === "shipped"
                ? "warning"
                : order?.status === "delivered"
                ? "success"
                : ""
            }
          >
            {order?.status}
          </Badge>
        </td>

        <td>{moment(order?.createdAt).format("D MMM,  YYYY")}</td>
      </tr>
    </>
  );
};

export default RecentDonors;
