import React, { useState } from "react";
import moment from "moment";
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import { BsXCircleFill } from "react-icons/bs";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  FormGroup,
  Input,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import { toast } from "react-toastify";
import bannerMethod from "../../Services/banner";
import { FaImage } from "react-icons/fa";

const Banner = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [status, setStatus] = useState(props.banner.status);

  const handleChange = (e, field) => {
    let fieldValue;

    if (e.target.type === "checkbox") {
      fieldValue = e.target.checked;
      setStatus(fieldValue);
    }
    setFields({
      ...fields,
      [field]: fieldValue,
    });

    if (field === "status") {
      const id = props.banner.id;

      bannerMethod.status(id, fieldValue).then((res) => {
        const position = toast.POSITION.TOP_RIGHT;

        if (res?.data?.status) {
          toast.success(res.data.message, { position });
        } else {
          toast.error(res.data.message, { position });
        }
      });
    }
  };


  const banner = props.banner;

  return (
    <tr key={banner.id}>
      <td>{props.index + 1}</td>
      <td>
        {
          banner?.image != null ?
          <img
          src={banner?.image}
          alt="govihub"
          className="img-thumbnail"
          style={{ width: "60px", minWidth: "60px" }}
        />
        : <FaImage />
        }
        
      </td>
      <td>{banner?.title}</td>
      <td>{banner?.heading}</td>
      <td>
      <FormGroup switch>
          <Input
            type="switch"
            name="status"
            onChange={(event) => handleChange(event, "status")}
            checked={status}
          />
        </FormGroup>
      </td>
      <td>{moment(banner?.createdAt).format("D MMM, YYYY")}</td>

      <td className="text-center">
        <Dropdown
          toggle={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          isOpen={dropdownOpen}
          color="primary"
          className="modal_Dropdown"
        >
          <DropdownToggle className="dropdown_btn">
            <FaEllipsisV />
          </DropdownToggle>
          <DropdownMenu container="body" right>
            <span onClick={() => props.openEditModal(banner.id)}>
              <DropdownItem>
                <MdModeEdit
                  className="me-3 edit-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />{" "}
                Edit
              </DropdownItem>
            </span>
            <span onClick={() => props.deleteCategory(banner.id)}>
              <DropdownItem>
                <BsXCircleFill
                  className="me-3 remove-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />{" "}
                Delete
              </DropdownItem>
            </span>
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default Banner;
