import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import { TiDelete } from "react-icons/ti";
import LoadingOverlay from "react-loading-overlay";
import prevettedsj from "../../Services/category";
import category from "../../Services/category";
import order from "../../Services/order";

const AddEditCategory = (props) => {
  const [fields, setFields] = useState({});
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [reFetch, setreFetch] = useState(false);

  LoadingOverlay.propTypes = undefined;

  const [orders, setOrders] = useState({});

  const getOrder = (id) => {
    let params = id;
    setLoader(true);
    order
      .getOne(params)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          console.log("GETONE", res?.data?.data?.order.Order_items[0]);
          setOrders(res?.data?.data?.order);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (props.accountId) {
      getOrder(props.accountId);
    }
  }, []);

  return (
    <>
      <Modal isOpen={props.accountOpen} size="lg" className="details_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          {`Order Id-#${orders?.id}`}
        </ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Price</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {orders?.Order_items &&
                orders?.Order_items?.map((ele, i) => (
                  <tr>
                    <td>
                      {" "}
                      <img
                        src={
                          ele?.Order_product?.Product_images && ele?.Order_product?.Product_images[0]?.image
                        }
                        style={{ height: "50px" }}
                      />
                    </td>
                    <td> {ele?.Order_product?.name}</td>
                    <td>₹{ele?.Order_product?.price}</td>
                    <td>
                      {" "}
                      {ele?.Order_product?.Product_categories &&
                        ele?.Order_product?.Product_categories[0]?.Category?.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <FormGroup row>
            <Col md={6}>
              {" "}
              <div className="nomination-box">
                <strong>User :</strong> {orders?.User_address?.name}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Mobile :</strong> {orders?.User_address?.mobile}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                <strong>Address :</strong>{" "}
                {orders?.User_address?.address &&
                  `${orders?.User_address?.address}`}
                {`${orders?.User_address?.State?.name}`}
                {`${orders?.User_address?.country?.name} ${orders?.User_address?.pincode}`}
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>PaymentMode :</strong> {`${orders?.payment_mode}`}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Status :</strong> {orders?.status}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Discount Amount :</strong> -₹{`${orders?.discount_amount}`}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Transaction Id :</strong> {orders?.transaction_id}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Sub Total :</strong> ₹{`${orders?.sub_total}`}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Shipping Charge :</strong> ₹{orders?.shipping_charge}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
          <Col col={6}>
              <div className="nomination-box">
                <strong>Total :</strong> ₹{orders?.total}
              </div>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            className="cancel_modal"
            onClick={props.handleClose}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditCategory;
