import http from "./http";
const common = {
  update_dmf_details: (data) => http.post("/update-dmf-details", data),
  dmfToken: (param) => http.get("/get-dmf-token-status", { params: param }),
  update: (data) => http.post("/admin/dmf-submissions/update-dmf-legal-info", data),
  grantTypes: ["DAF"],

  getFullName(data) {
    let name = "";

    if (data !== null && data.first_name !== null) {
      name += data?.first_name + ` `;
    }

    if (data !== null && data.last_name !== null) {
      name += data?.last_name + ` `;
    }

    return name;
  },
  isValidEmail: (email) => {
    let emailRule = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRule.test(email)) {
      return true;
    }
    return false;
  },

  //Amount Fomat

  FormatAmount: (amount) => {
    let formattedAmount = Number(amount || 0)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    formattedAmount = "₹" + formattedAmount;
    return formattedAmount;
  },


};

export default common;
