import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import { TiDelete } from "react-icons/ti";
import LoadingOverlay from "react-loading-overlay";
import prevettedsj from "../../Services/category";
import category from "../../Services/category";

const AddEditCategory = (props) => {
  const [fields, setFields] = useState({});
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [reFetch, setreFetch] = useState(false);

  LoadingOverlay.propTypes = undefined;
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.name) {
      errors["name"] = "Name can not be empty";
      formIsValid = false;
    }
    if(!file || !imagePreview){
      errors["file"] = "Image can not be empty";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)
    setFile(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);
    category
      .uploadImage(formData)
      .then((res) => {
        if (res.data.status) {
          setImagePreview(res.data.data.filePath);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Image uploading error", error);
      });
  };

  const getCategory = () => {
    let params = props.categoryId;
    setLoader(true);
    category
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          setFields(res?.data?.data?.category);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleImageDelete = () => {
    let params = { filePath: fields?.image };
    category
      .deleteImage(params)
      .then((res) => {
        if (res.data.status) {
          setFile(null);
          setImagePreview(null);

          const fileInput = document.getElementById("file");
          if (fileInput) {
            fileInput.value = "";
          }
          setFields({ ...fields, image: null });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Image delete error", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){


    let params = fields;
    params["image"] = imagePreview || fields.image;
    setIsloader(true);
    const categoryMethod = props.categoryId ? category.update : category.add;
    categoryMethod(params)
      .then((res) => {
        setIsloader(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFields({});
          setIsloader(true);
          props.handleClose();
          props.getCategories();
        } else {
          let errors = {};
          for (let key in res?.data?.errors) {
            errors[key] = res?.data?.errors[key];
          }
          setErrors(errors);
          setIsloader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
    }
  };
  useEffect(() => {
    if (props.categoryId) {
      getCategory(props.categoryId);
    }
  }, [reFetch]);

  return (
    <>
      <Modal isOpen={props.open} size="md" className="admin new-grantee-modal">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            {props.categoryId ? "Update " : "Add "}Category
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={<Spinner style={{ color: "#00344B" }} />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder=""
                      type="text"
                      value={fields["name"] ? fields["name"] : ""}
                      onChange={(event) => handleChange(event, "name")}
                      invalid={errors["name"] ? true : false}
                    />
                    <FormFeedback>{errors["name"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="image">Image</Label>
                    <Input
                      type="file"
                      name="image"
                      id="image"
                      onChange={handleFileUpload}
                      invalid={errors["image"] ? true : false}
                    />
                    <FormFeedback>{errors["image"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {imagePreview !== null ? (
                    <FormGroup className="text-center">
                      <div className="image-container">
                        <img
                          src={imagePreview}
                          className="img-thumbnail img-fluid preview-image"
                          alt="govihub"
                        />
                        <span
                          className="close-icon"
                          onClick={handleImageDelete}
                        >
                          <TiDelete />
                        </span>
                      </div>
                    </FormGroup>
                  ) : fields.image ? (
                    <FormGroup className="text-center">
                      <div className="image-container">
                        <img
                          src={fields.image}
                          className="img-thumbnail img-fluid preview-image"
                          alt="govihub"
                        />
                        <span
                          className="close-icon"
                          onClick={handleImageDelete}
                        >
                          <TiDelete />
                        </span>
                      </div>
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="submit-Id-button"
              size="md"
              type="submit"
              disabled={isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              {props.categoryId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditCategory;
