import React, { useState } from "react";
import moment from "moment";
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsXCircleFill } from "react-icons/bs";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";

const Category = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const category = props.cate;
  return (
    <tr key={category.id}>
      <td>{props.index + 1}</td>
      <td>
       <img src={category?.image} alt="govihub" className="img-thumbnail" style={{width:"60px",minWidth:"60px"}} />
      </td>
      <td>{category?.name}</td>
      <td>{moment(category?.createdAt).format("D MMM,  YYYY")}</td>


      <td className="text-center">
        <Dropdown
          toggle={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          isOpen={dropdownOpen}
          color="primary"
          className="modal_Dropdown"
        >
          <DropdownToggle className="dropdown_btn">
            <FaEllipsisV />
          </DropdownToggle>
          <DropdownMenu container="body" right>
     
              <span   onClick={() => props.openEditModal(category.id)}>
            <DropdownItem>
                <MdModeEdit
                  className="me-3 edit-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                /> Edit
            </DropdownItem>
              </span>
              <span    onClick={() => props.deleteCategory(category.id)}>
            <DropdownItem>
                <BsXCircleFill
                  className="me-3 remove-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                /> Delete
            </DropdownItem>
              </span>
          </DropdownMenu>
        </Dropdown>
      </td>
      
    </tr>
  );
};

export default Category;
