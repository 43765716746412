import React, {useCallback } from "react";
import {
  Collapse,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const Index = (props) => {
  const {setFields, fields, onSubmit } = props
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const handleChange = (field, e) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSubmit(fields);
  };

  const clearSearch = useCallback(
    (e) => {
      e.preventDefault();
      setFields({});
      onSubmit({});
    },
    [setFields]
  );

  return (
    <Collapse isOpen={props.isOpen}>
      <Card className="m-2" style={{ textAlign: 'left' }}>
        <CardHeader>{props.heading}</CardHeader>
        <CardBody>
          <Form name="add-edit-action-form" method="post">
            <Row>
              {props.searchFields.length > 0 &&
                props.searchFields.map((ele, index) => (
                  <Col
                    md={ele.grid !== undefined ? ele.grid : 4}
                    key={`search-field-key-${index}`}
                  >
                    <FormGroup className="mb-2 mr-sm-2 mb-3">
                      <Label className="mr-sm-2">{ele.label}</Label>
                      {ele.type === "text" && (
                        <Input
                          type={ele.type}
                          name={ele.name}
                          id={ele.name}
                          value={fields[ele.name] ? fields[ele.name] : ""}
                          onChange={(event) => handleChange(ele.name, event)}
                          className="input-bg"
                          autoComplete="none"
                        />
                      )}
                      {ele.type === "number" && (
                        <Input
                          type={ele.type}
                          name={ele.name}
                          id={ele.name}
                          min={0}
                          value={fields[ele.name] ? fields[ele.name] : ""}
                          onChange={(event) => handleChange(ele.name, event)}
                          className="input-bg"
                          autoComplete="none"
                          onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                      )}
                      {ele.type === "select" && (
                        <Input
                          type={ele.type}
                          id={ele.type}
                          name={ele.name}
                          value={fields[ele.name] ? fields[ele.name] : ""}
                          onChange={(event) => handleChange(ele.name, event)}
                        >
                          <option>--Select Status--</option>
                          <option value={1}>Active</option>
                          <option value={0}>InActive</option>
                        </Input>
                      )}
                    </FormGroup>
                  </Col>
                ))}
            </Row>
            <Row>
              <Col lg={12} className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-danger search_btn me-2 text-white"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={clearSearch}
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Collapse>
  );
};

export default Index;
