import React from "react";
import { AiFillContainer } from "react-icons/ai";
import { SiTodoist } from "react-icons/si";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import "./status.css";
import {FiArrowUpRight,FiArrowDownRight} from "react-icons/fi"

const StatusCard = ({ fields, Loader }) => {
  console.log(fields);
  const {
    total_user_count,
    totla_order_count,
    total_product_count,
  } = fields;

  const statusCard = [
    {
      title: "Orders",
      icon: <SiTodoist size={80} className="fas me-3 mt-3" />,
      data: totla_order_count,
      class1: "submission",
      class2: "submission-bar",
    },

    {
      title: "Users",
      icon: <AiFillContainer size={100} className="fas" />,
      data: total_user_count,
      class1: "nomination2",
      class2: "nomination2-bar",
    },
    {
      title: "Products",
      icon: <AiFillContainer size={100} className="fas" />,
      data: total_product_count,
      class1: "nomination",
      class2: "nomination-bar",
    },
  ];
  return (
    <div className="d-flex justify-content-between">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
        integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk="
        crossorigin="anonymous"
      />

      <div className="col-md-12 ">
        <div className="row ">
          {statusCard.map((status, i) => {
            const { title, icon, data, percentage, route, class1, class2 } =
              status;
            return (
              <div className="history-cart col-xl-4 col-md-4 col-lg-6" key={i}>
                <div className={`card ${class1}`}>
                  <div className="card-statistic-3 p-3">
                    {/* <div className="card-icon card-icon-large">{icon}</div> */}
                    <div className="mb-3">
                      <h6 className="card-title mb-0 text-center title-font card_header">
                        <Link
                          to={route}
                          style={{ textDecoration: "none", color: "#00344B" }}
                        >
                          {title}
                        </Link>
                      </h6>
                    </div>
                    <div className="row percentage">
                      <div className="col-12 text-center">
                        {percentage ? (
                          <span>
                            {percentage}%
                            {percentage >= 0 ? (
                              <FiArrowUpRight size={20}/>
                            ) : (
                              <FiArrowDownRight size={20}/>
                            )}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row mb-1 d-flex">
                      <div className="col-12">
                        <h1 className="text-center mb-4 mt-5">
                          {Loader ? (
                            <Spinner size="sm" className="mx-1" />
                          ) : (
                            <span>{data}</span>
                          )}
                        </h1>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
