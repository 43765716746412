import React, { useState, useEffect } from "react";
import moment from "moment";
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsXCircleFill } from "react-icons/bs";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import OrderDetails from "./OrderDetails";
import order from "../../Services/order";
import { toast } from "react-toastify";

const Category = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [accountOpen, setACcountOpen] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [fields, setFields] = useState({ status: props?.order?.status });
  // const [status, setStatus] = useState(props?.order?.status);
  const [products, setProducts] = useState([]);

  const OrderModal = (accoun_id) => {
    setACcountOpen(true);
    setAccountId(accoun_id);
  };

  const handleChange = async (e, field) => {
    const newStatus = e.target.value;
    setFields({ ...fields, [field]: newStatus });
  
    // Check if the selected value is not empty before making the API call
    if (newStatus !== "") {
      // Call API to update order status
      try {
        const res = await order.status({ id: props.order.id, status: newStatus });
        const position = toast.POSITION.TOP_RIGHT;
  
        if (res?.data?.status) {
          toast.success(res.data.message, { position });
        } else {
          toast.error(res.data.message, { position });
        }
      } catch (error) {
        console.error("Error updating order status", error);
        const position = toast.POSITION.TOP_RIGHT;
        toast.error("Error updating order status", { position });
      }
    }
  };
  

  useEffect(() => {
    props?.order?.Order_items &&
      props?.order?.Order_items?.map((prod, i) => {
        return setProducts(prod?.Order_product);
      });
  }, [props?.order?.Order_items]);

  return (
    <>
      <tr>
        <td>{props?.index + 1}</td>
        <td>#{props.order?.id}</td>
        <td>{props?.order?.User?.name}</td>
        <td> ₹{products?.price}</td>
        <td>
        <td>
  <FormGroup>
    <Input
      id="status"
      name="status"
      type="select"
      value={fields["status"] ? fields["status"] : ""}
      onChange={(event) => handleChange(event, "status")}
    >
      <option value="">---Select status---</option>
      <option
        value="shipped"
        disabled={props.order.status === "shipped" || props.order.status === "delivered"}
      >
        shipped
      </option>
      <option
        value="delivered"
        disabled={props.order.status === "delivered"}
      >
        delivered
      </option>
    </Input>
  </FormGroup>
</td>

        </td>
        <td>{moment(props?.order?.createdAt).format("D MMM,  YYYY")}</td>

        <td className="text-center">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => OrderModal(props?.order?.id)}>
                <FaEye
                  className="me-3 details-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />
                Details
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>

      {accountOpen && (
        <OrderDetails
          accountOpen={accountOpen}
          handleClose={() => {
            setACcountOpen();
            setAccountId(null);
          }}
          accountId={accountId}
          //   id={user.id}
        />
      )}
    </>
  );
};

export default Category;
